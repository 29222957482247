import type { BrandVariants } from '../types';

export const brandWeb: BrandVariants = {
  10: `#061724`,
  20: `#082338`,
  30: `#0a2e4a`,
  40: `#0c3b5e`,
  50: `#0e4775`,
  60: `#0f548c`,
  70: `#115ea3`,
  80: `#0f6cbd`,
  90: `#2886de`,
  100: `#479ef5`,
  110: `#62abf5`,
  120: `#77b7f7`,
  130: `#96c6fa`,
  140: `#b4d6fa`,
  150: `#cfe4fa`,
  160: `#ebf3fc`,
};

export const brandTeams: BrandVariants = {
  10: `#2b2b40`,
  20: `#2f2f4a`,
  30: `#333357`,
  40: `#383966`,
  50: `#3d3e78`,
  60: `#444791`,
  70: `#4f52b2`,
  80: `#5b5fc7`,
  90: `#7579eb`,
  100: `#7f85f5`,
  110: `#9299f7`,
  120: `#aab1fa`,
  130: `#b6bcfa`,
  140: `#c5cbfa`,
  150: `#dce0fa`,
  160: `#e8ebfa`,
};

export const brandOffice: BrandVariants = {
  10: `#29130b`,
  20: `#4d2415`,
  30: `#792000`,
  40: `#99482b`,
  50: `#a52c00`,
  60: `#c33400`,
  70: `#e06a3f`,
  80: `#d83b01`,
  90: `#dd4f1b`,
  100: `#fe7948`,
  110: `#ff865a`,
  120: `#ff9973`,
  130: `#e8825d`,
  140: `#ffb498`,
  150: `#f4beaa`,
  160: `#f9dcd1`,
};
