import React from "react";
import { AppContext } from "@utils/AppContext";
import { GridLayout } from "@components/MWF/GridLayout";
import { ScreenSizeBreakpoint } from "@utils/MWF/ScreenSizeBreakpoints";
import { ProductDetails } from "@models/ProductDetails";
import { LoadState } from "@utils/Enums/LoadState";
import { HTTPError } from "@errors/HttpError";
import { History } from 'history';
import { HttpStatusCode } from "@utils/Http/HttpStatusCode";
import { CatalogAPI } from "@utils/APIs/CatalogAPI";
import { Markdown } from "@components/Markdown/Markdown";
import { LinkButton } from "@components/LinkButton";
import { autobind } from "@utils/Decorators";
import { TabList, Tab, SelectTabData, SelectTabEvent, FluentProvider, webLightTheme } from "@fluentui/react-components";
import { Icon } from "@fluentui/react";
import { Spinner } from "@components/MWF/Spinner";
import { DeveloperError } from "@errors/DeveloperError";
import pageStyles from "./ProductPage.module.css";
import mobileStyles from "@styles/MobileHelper.module.css";
import { Tag } from "@models/Tag";
import { TagTable } from "./TagTable";
import styles from "./ProductPage.module.css";

import { LoremIpsum } from 'lorem-ipsum';
import { DOMUtils } from "@utils/DOMUtils";
import { ProductSubRoute } from "./ProductSubRoute";
import { AppRoute } from "@components/Routing/AppRoute";

const SPINNER_ARIA_ID = DOMUtils.generateDOMUuid();
const TAG_SPINNER_ARIA_ID = DOMUtils.generateDOMUuid();

interface IProductPageProps {
  repo: string;
  history: History;
  page: string;
}

interface IProductPageState {
  product?: ProductDetails;
  loadState: LoadState;
  failureMessage?: string;
  tags: Tag[],
  tagLoadState: LoadState;
  tagFailureMessage?: string;
}

interface IMetadataItem {
  label: string;
  url?: string;
  icon?: string;
}

export class ProductPage extends React.Component<IProductPageProps, IProductPageState> {

  public static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  constructor(props: IProductPageProps) {
    super(props);
    this.state = {
      product: undefined,
      loadState: LoadState.Loading,
      failureMessage: undefined,
      tags: [],
      tagLoadState: LoadState.Loading,
      tagFailureMessage: undefined
    };
  }

  public async componentDidMount() {
    if (this.props.repo) {
      var loadedProduct = await this.loadProductDetails();
      if (loadedProduct) {
        await this.loadProductTags();
      } else {
        this.setState({
          tagLoadState: LoadState.Failed,
          failureMessage: this.context.languagePack.product_page.errors.load_failed.replace("{0}", this.props.repo)
        });
      }
    } else {
      //If a repo isn't provided, send the user back to the root page.
      const route = AppRoute.getRoute(AppRoute.Root, this.context.locale);
      this.props.history.push(route);
    }
  }

  private async loadProductDetails() {
    let success = false;
    try {
      let data = await CatalogAPI.getProductDetails(this.props.repo);
      this.setState({
        product: data,
        loadState: LoadState.Complete
      });
      success = true;
    } catch (err) {
      if (err instanceof HTTPError) {
        if (err.statusCode === HttpStatusCode.NotFound) {
          const route = AppRoute.getRoute(AppRoute.NotFound, this.context.locale);
          this.props.history.push(route);
        }
        this.setState({
          loadState: LoadState.Failed,
          failureMessage: err.userMessage
        });
      } else {
        this.setState({
          loadState: LoadState.Failed,
          failureMessage: this.context.languagePack.product_page.errors.load_failed.replace("{0}", this.props.repo)
        });
      }
    }
    return success;
  }

  private async loadProductTags() {
    try {
      let data = await CatalogAPI.getProductTags(this.props.repo);
      this.setState({
        tags: data,
        tagLoadState: LoadState.Complete
      });
    } catch (err) {
      if (err instanceof HTTPError) {
        this.setState({
          tagLoadState: LoadState.Failed,
          tagFailureMessage: err.userMessage
        });
      } else {
        this.setState({
          tagLoadState: LoadState.Failed,
          tagFailureMessage: this.context.languagePack.product_page.errors.tag_load_failed.replace("{0}", this.props.repo)
        });
      }
    }
  }

  @autobind
  private returnToCatalog(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    /* 
    Prevent default is used here to keep the href used in LinkButton from being activated.
    Without this line, the href would cause the page to be reloaded when the button is clicked, which we don't want.
    However, the href still needs to be included to make the link accessible to web crawlers and to open page in new tab.
    */
    event.preventDefault();
    // If the user navigated directly to the product page, there will be no history. 
    // In this case, we will send them to the catalog page.
    if(this.props.history.length <= 2)
    {
      const route = AppRoute.getRoute(AppRoute.Catalog, this.context.locale);
      this.props.history.push(route);
    }
    else
    {
      this.props.history.goBack();
    }
  }

  private renderMetadata(mobile = false) {
    if (this.state.product) {
      if (mobile) {

        return (
          <div className={`${pageStyles["metadata-mobile"]} mb-2 mb-md-0`}>
            <GridLayout>
              <GridLayout.Row>
                {this.state.product.lastModifiedDate !== undefined ? this.renderMetadataList(this.context.languagePack.product_page.last_pushed, [{label: this.state.product.lastModifiedDate?.format("L")}], true) : null}
                {this.state.product.totalPullCount !== undefined ? this.renderMetadataList(this.context.languagePack.product_page.metadata.headers.total_pull_count, [{label: this.state.product.totalPullCount}], true) : null}
                {this.state.product.categories !== undefined ? this.renderMetadataList(this.context.languagePack.product_page.metadata.headers.categories, this.state.product.categories.map(s => ({label: s})), true) : null}
                {this.renderProjectMetadata(true)}
                {this.renderHelpMetadata(true)}
              </GridLayout.Row>
            </GridLayout>
          </div>
        );
      } else {
        return (
          <div className={`${pageStyles["metadata-desktop"]}`}>
            {this.renderProductImage()}
            {this.state.product.lastModifiedDate && this.renderMetadataList(this.context.languagePack.product_page.last_pushed, [{label: this.state.product.lastModifiedDate?.format("L")}])}
            {this.renderPullCount()}
            {this.state.product.categories !== undefined && this.renderMetadataList(this.context.languagePack.product_page.metadata.headers.categories, this.state.product.categories.map(s => ({label: s})))}
            {this.renderProjectMetadata()}
            {this.renderHelpMetadata()}
          </div>
        );
      }
    } else {
      throw new DeveloperError("Called renderMetadata before the product has loaded.");
    }
  }
  
  private renderProjectMetadata(mobile = false) {
    if (this.state.product) {
      let items: IMetadataItem[] = [];
      if (this.state.product.projectWebsite) {
        items.push({label: this.context.languagePack.product_page.metadata.project.website, url: this.state.product.projectWebsite, icon:"Globe"});
      }
      if (this.state.product.projectRepostioryUrl) {
        items.push({label: this.context.languagePack.product_page.metadata.project.repository, url: this.state.product.projectRepostioryUrl, icon:"OpenSource"});
      }
      if (this.state.product.licenseType?.toLowerCase() !== "other") {
        items.push({label: this.state.product.licenseType + " " + this.context.languagePack.product_page.metadata.project.license, icon: "Certificate"});
      }
      else if (this.state.product.licenseUrl) {
        items.push({label: this.context.languagePack.product_page.metadata.project.license, url: this.state.product.licenseUrl, icon: "Certificate"});
      }
      return this.renderMetadataList(this.context.languagePack.product_page.metadata.headers.project, items, mobile);
    }
    return null;
  }

  private renderHelpMetadata(mobile = false) {
    if (this.state.product && (this.state.product.supportLink || this.state.product.documentationLink)) {
      let items: IMetadataItem[] = [];
      if (this.state.product.documentationLink) {
        items.push({label: this.context.languagePack.product_page.metadata.help.documentation, url: this.state.product.documentationLink});
      }
      if (this.state.product.supportLink) {
        items.push({label: this.context.languagePack.product_page.metadata.help.support, url: this.state.product.supportLink});
      }
      return this.renderMetadataList(this.context.languagePack.product_page.metadata.headers.help, items, mobile);
    }
    return null;
  }

  private renderProductImage() {
    return (
      <picture className={pageStyles["product-image"]}>
        <img alt={this.state.product?.imageAltText} src={this.state.product?.imagePath} className="img-fluid" />
      </picture>
    );
  }

  private renderMetadataList(title: string, items: IMetadataItem[], mobile = false) {
    const component = (
      <div className={`${pageStyles["metadata-list"]} mt-0 mt-md-4`}>
        <h6 className="mb-0 mb-md-2">{title}</h6>
        {items.map((item, i) => (
          <div key={`val_${i}`} className={`${pageStyles["metadata-item"]}`}>
            {item.icon && <Icon iconName={item.icon}/>}
            {item.url ? 
              <a href={item.url}>{item.label}</a> :
              <label>{item.label}</label>}
          </div>
        ))}
      </div>
    );
    if (mobile) {
      return (
        <GridLayout.Column widthSettings={[
          [ScreenSizeBreakpoint.xs, "auto"]
        ]}>
          {component}
        </GridLayout.Column>
      )
    } else {
      return component;
    }
  }

  private renderBreadcrumbs() {
    //Initally we will just have a back button.
    return (
      <div className={`${pageStyles["breadcrumb-wrapper"]} my-2 mt-lg-0 mb-lg-3`}>
        <LinkButton
          href={AppRoute.getRoute(AppRoute.Catalog, this.context.locale)}
          aria-label={this.context.languagePack.product_page.back_to_catalog}
          className="cta"
          onClick={(event) => this.returnToCatalog(event)}
        >
          {this.context.languagePack.product_page.back_to_catalog}
        </LinkButton>
      </div>
    );
  }

  private renderLoading(): JSX.Element {
    return (
      <div className={pageStyles["no-content-wrapper"]}>
        <Spinner large ariaId={SPINNER_ARIA_ID} />
      </div>
    );
  }

  private renderFailed(): JSX.Element {
    return (
      <div className={pageStyles["no-content-wrapper"]}>
        {this.state.failureMessage}
      </div>
    );
  }
  
  private renderTitleArea() {
    return (
      <div className={pageStyles["title-area"]}>
        <div className={`${mobileStyles["mobile-only"]} ${pageStyles["mobile-product-image-wrapper"]}`}>
          {this.renderProductImage()}
        </div>
        <div>
          <h4>{this.state.product?.name}</h4>
          <label>{this.state.product?.publisher}</label>
        </div>
        <div className={`${pageStyles["disclaimer-spacing"]}`}></div>
        <div>
          {this.shouldDisplayMicrosoftOnlyDisclaimer() &&
          <div className="alert alert-dismissible fade show" role="alert" aria-live="polite">
            <div className="alert-content">
              {this.context.languagePack.catalog_page.banner_message.unlisted_title}
            </div>
          </div>}
        </div>
      </div>
    );
  }

  private renderTagTable() {
    let result: React.ReactNode;
    switch (this.state.tagLoadState) {
      case LoadState.Loading:
        result = (
          <div className={styles["tag-table-spinner"]}>
            <Spinner large ariaId={TAG_SPINNER_ARIA_ID} />
          </div>
        );
        break;
      case LoadState.Complete:
        result = (
          <TagTable
            tags={this.state.tags}
            repo={this.props.repo}
            supportedTags={this.state.product ? this.state.product.supportedTags : []}
          />
        );
        break;
      case LoadState.Failed:
        break;
    }
    return result;
  }

  @autobind
  private handleContentPageChanged(event?: SelectTabEvent, data?: SelectTabData) {
    const value = data?.value ?? undefined;
    if (value) {
      const extension = `/${this.props.repo}/${value}`;
      const route = AppRoute.getRoute(AppRoute.Product, this.context.locale, extension);
      this.props.history.replace(route);
    }
  }

  private renderReadme() {
    if (this.state.product?.readme) {
      return (
        <Markdown>
          {this.state.product?.readme}
        </Markdown>
      )
    } else {
      return (
        <span className={pageStyles["no-readme"]}>{this.context.languagePack.product_page.errors.no_readme}</span>
      )
    }
  }

  private renderContentTab(page: string = ProductSubRoute.Tags) {
    // Note: FluentProvider is the style/design provider for fluent v9 (similar to ThemeProvider). If additional v9 components are added 
    // in the future, the provider can be moved up to the app level. However, this may cause some MWF 
    // components to change slightly in their styling (such as LinkButton)
    return (
      <FluentProvider theme={webLightTheme} className={pageStyles.tabs}>
        <TabList aria-label={this.context.languagePack.product_page.tab_controls.aria_label} onTabSelect={this.handleContentPageChanged} selectedValue={page}>
          <Tab value={ProductSubRoute.About} aria-label={this.context.languagePack.product_page.tab_controls.about}>
            {this.context.languagePack.product_page.tab_controls.about}
          </Tab>
          <Tab value={ProductSubRoute.Tags} aria-label={this.context.languagePack.product_page.tab_controls.tags}>
            {this.context.languagePack.product_page.tab_controls.tags}
          </Tab>
        </TabList>
        <div>
          {this.props.page === ProductSubRoute.About && 
            <div className={`p-3 ${pageStyles["readme-area"]}`}>
              {this.renderReadme()}
            </div>}
          {this.props.page === ProductSubRoute.Tags && 
            <div className="p-3">
              {this.renderTagTable()}
            </div>}
        </div>
      </FluentProvider>
    );
  }

    
  public shouldDisplayMicrosoftOnlyDisclaimer = () => 
  {
    let show = false;
    if(this.state?.product?.categories)
    {
      this.state.product.categories.forEach(cat => {
        if(cat.includes("Microsoft Only"))
        {
          show = true;
         return show;
        }

      });
    }

    return show;
  }

  public renderPullCount = () =>
  {
    if(this.state.product?.totalPullCount === "N/A") {
      return (<div title={this.context.languagePack.product_page.errors.no_pull_count}>{this.state.product.totalPullCount && 
        this.renderMetadataList(this.context.languagePack.product_page.metadata.headers.total_pull_count, 
        [{label: this.state.product.totalPullCount}])}</div>);
    }
    else {
      return(<div>{this.state.product?.totalPullCount && this.renderMetadataList(this.context.languagePack.product_page.metadata.headers.total_pull_count, [{label: this.state.product.totalPullCount}])}</div>);
    }
    
  }


  private renderLoaded() {
    if (this.state.product) {
      return (
        <>
          <GridLayout className={`${pageStyles.container} px-2 px-md-0`}>
            <GridLayout.Row noGutters>
              {this.renderBreadcrumbs()}
            </GridLayout.Row>
            <GridLayout.Row>
              <GridLayout.Column
                widthSettings={[
                  [ScreenSizeBreakpoint.md, 3],
                  [ScreenSizeBreakpoint.xl, 2]
                ]}
                desktopOnly
              >
                {this.renderMetadata(false)}
              </GridLayout.Column>
              <GridLayout.Column
                widthSettings={[
                  [ScreenSizeBreakpoint.md, 9],
                  [ScreenSizeBreakpoint.xl, 10]
                ]}
              >
                <GridLayout.Row>
                  {this.renderTitleArea()}
                </GridLayout.Row>
                <GridLayout.Row mobileOnly>
                  {this.renderMetadata(true)}
                </GridLayout.Row>
                <GridLayout.Row>
                  {this.renderContentTab(this.props.page)}
                </GridLayout.Row>
              </GridLayout.Column>
            </GridLayout.Row>
          </GridLayout>
        </>
      );
    } else {
      return null;
    }

  }

  public render() {
    let result: JSX.Element | null = null;
    switch (this.state.loadState) {
      case LoadState.Complete:
        result = this.renderLoaded();
        break;
      case LoadState.Failed:
        result = this.renderFailed();
        break;
      case LoadState.Loading:
        result = this.renderLoading();
        break;
    }
    return result;
  }
}