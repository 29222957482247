export enum ProductSubRoute {
  Tags = "tags",
  About = "about",
  Default= "",
}

export namespace ProductSubRoute {
  export const itr = function* () {
    yield ProductSubRoute.About;
    yield ProductSubRoute.Tags;
    yield ProductSubRoute.Default;
  }
}