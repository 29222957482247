import moment, { Moment } from "moment";

export interface ITag {
  name: string;
  digest: string;
  repository: string;
  manifestType: string;
  artifactType: string;
  size?: number;
  operatingSystem?: string;
  architecture?: string;
  lastModifiedDate?: string;
}

export class Tag {

  readonly name: string;
  readonly manifestType: string;
  readonly artifactType: string;
  readonly digest: string;
  readonly repository: string;
  readonly operatingSystem?: string;
  readonly architecture?: string;
  readonly lastModifiedDate?: Moment;
  readonly size?: number;

  protected constructor(tagData: ITag) {
    this.name = tagData.name;
    this.manifestType = tagData.manifestType;
    this.artifactType = tagData.artifactType;
    this.digest = tagData.digest;
    this.size = tagData.size;
    this.repository = tagData.repository;
    this.operatingSystem = tagData.operatingSystem;
    this.architecture = tagData.architecture;
    this.lastModifiedDate = tagData.lastModifiedDate ? moment(tagData.lastModifiedDate) : undefined;
  }

  public static fromJsonData(tagData: ITag) {
    return new Tag(tagData);
  }
}