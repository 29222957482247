import { AppContext } from '@utils/AppContext';
import { AnimatedHeightDiv } from "@components/Animated/AnimatedHeightDiv/AnimatedHeightDiv";
import { DeveloperError } from "@errors/DeveloperError";
import { autobind } from "@utils/Decorators";

import React from "react";
import { Filter } from "@utils/Filter";
import * as uuid from 'uuid';
import { PaginationControl } from "../Pagination";

import tableStyles from "./Table.module.css";
import { Icon } from "@fluentui/react";
import { Debug } from "@utils/Debug/Debug";
import moment from "moment";
import { DOMUtils } from "@utils/DOMUtils";

enum TableClasses {
  Small = "table-sm",
  LargeHeaders = "table-lg-headers",
  FirstColumnHighlight = "table-first-col-highlight",
  Striped = "table-striped",
  ExtraBorders = "table-bordered",
  Borderless = "table-borderless",
  //These are not choices for the top level table class, but are classes that will go in the table somewhere.
  HeaderHighlight = "thead-highlight",
  Responsive = "table-responsive",
  Default = "table"
}

namespace TableClasses {
  //Any is used here because we don't care what T is.
  export function fromProps(props: ITableProps<any>): string {
    let classes = [TableClasses.Default];
    if (props.small) {
      classes.push(TableClasses.Small);
    }
    if (props.largeHeaders) {
      classes.push(TableClasses.LargeHeaders);
    }
    if (props.firstColumnHighlight) {
      classes.push(TableClasses.FirstColumnHighlight);
    }
    if (props.striped) {
      classes.push(TableClasses.Striped);
    }
    if (props.extraBorders) {
      classes.push(TableClasses.ExtraBorders);
    }
    if (props.borderless) {
      classes.push(TableClasses.Borderless);
    }
    return classes.join(' ');
  }
}

type SortType = 'string' | 'number' | 'date';

export interface ITableColumn<T> {
  colId?: string;
  headerName: string;
  field?: Extract<keyof T, string>
  isRowHeader?: boolean;
  valueGetter?: (data: T) => any;
  valueFormatter?: (params: IValueFormatterParams<T>) => any;
  cellRenderer?: (params: ICellRendererParams<T>) => React.ReactNode
  sortFunction?: SortType | ((a: T, b: T) => number)
}

interface IValueFormatterParams<T> {
  data: T,
  column: ITableColumn<T>,
  originalValue: any;
}

interface ICellRendererParams<T> {
  data: T,
  originalValue: any,
  column: ITableColumn<T>,
  stringValue: string;
}


interface ITableProps<T> {
  columns: ITableColumn<T>[];
  items: T[];
  defaultSort?: [Extract<keyof T, string>, SortType] | ((a: T, b: T) => number)
  small?: boolean;
  largeHeaders?: boolean;
  headerHighlight?: boolean;
  firstColumnHighlight?: boolean;
  striped?: boolean;
  extraBorders?: boolean;
  borderless?: boolean;
  caption?: string;
  responsive?: boolean;
  isPresentationOnly?: boolean;
  renderCollapseContent?: (item: T, open:boolean) => JSX.Element | JSX.Element[]
  shouldRenderCollapseContent?: (item: T) => boolean;
  filter?: Filter<T>
  itemsPerPage?: number;
}

interface ITableState<T> {
  items: T[];
  currentPage: number;
  sortSetting?: {
    colId: string,
    direction: 'asc' | 'desc'
  }
}

export class Table<T> extends React.Component<ITableProps<T>, ITableState<T>> {

  public static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  constructor(props: ITableProps<T>) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
    this.preprocessColumns();
    this.state = {
      items: this.props.items,
      currentPage: 1,
      sortSetting: undefined
    };
  }

  componentDidUpdate() {
    this.preprocessColumns();
  }

  private get usePagination() {
    return this.props.itemsPerPage !== undefined;
  }

  private get totalPages() {
    if (this.usePagination && this.props.itemsPerPage !== 0) {
      let items = this.props.filter ? this.props.items.filter(this.props.filter.passes) : this.props.items;
      let totalItems = items.length;
      let totalPages = Math.ceil(totalItems / this.props.itemsPerPage!);
      return totalPages;
    } else {
      return -1;
    }
  }

  private renderTable(): JSX.Element {
    const rows = this.renderRows();
    return (
      <>
        <table className={TableClasses.fromProps(this.props)} role={'treegrid'}>
          {this.props.caption && <caption>{this.props.caption}</caption>}
          <thead className={this.props.firstColumnHighlight ? TableClasses.FirstColumnHighlight : undefined}>
            <tr>
              {this.renderHeaders()}
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        <div aria-live="polite">
          {rows.length > 0 ? this.renderPagination() : this.renderNoResults()}
        </div>
      </>
    )
  }

  private renderPagination() {
    return (
      <PaginationControl justification='center' totalPages={this.totalPages} currentPage={this.state.currentPage} ariaLabel={"Tag table pagination control"} onPageChanged={this.onPageChanged} />
    );
  }

  private renderNoResults() {
    return <div className={tableStyles["no-content"]}>No Results</div>
  }

  private toggleSortWithKeyboard(colDef: ITableColumn<T>, event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      this.toggleSortForColumn(colDef);
    }
  }

  private toggleSortForColumn(colDef: ITableColumn<T>) {
    if (colDef.sortFunction) {
      if (this.state.sortSetting && colDef.colId === this.state.sortSetting.colId) {
        switch (this.state.sortSetting.direction) {
          case 'asc':
            this.setState({
              sortSetting: {
                colId: colDef.colId!,
                direction: 'desc'
              }
            });
            break;
          case 'desc':
            this.setState({
              sortSetting: undefined
            });
            break;
        }
      } else {
        this.setState({
          sortSetting: {
            colId: colDef.colId!,
            direction: 'asc'
          }
        });
      }
    }
  }

  private renderHeaders(): JSX.Element[] {
    let headers = this.props.columns.map(column => {
      const inUse = this.state.sortSetting && this.state.sortSetting.colId === column.colId;
      return (
        <th
          onClick={() => this.toggleSortForColumn(column)}
          onKeyPress={(event: React.KeyboardEvent) => this.toggleSortWithKeyboard(column, event)}
          key={`col_${column.headerName}`}
          scope='col'
          aria-label={`Sort ${column.headerName} column`}
          tabIndex={0}
        >
          <div className={tableStyles["header-container"]}>
            <span>{column.headerName}</span>
            {column.sortFunction !== undefined && (
              <span className={`${tableStyles["header-icon"]} ${inUse ? "" : tableStyles.hidden}`}>
                <Icon iconName={this.state.sortSetting?.direction === 'asc' ? 'SortUp' : 'SortDown'} />
              </span>
            )}
          </div>
        </th>
      )
    });
    if (this.props.renderCollapseContent) {
      headers.unshift(<th key="blank_slot" aria-label="Row Expansion Control" tabIndex={0}/>)
    }
    return headers;
  }

  private processDefaultSort(items: T[], type: SortType, field: Extract<keyof T, string>): void {
    switch (type) {
      case "string":
        items.sort((a, b) => {
          //We already know these are set, but the compiler can't tell.
          const aVal = String(a[field]);
          const bVal = String(b[field]);
          return aVal.localeCompare(bVal, this.context.locale);
        });
        break;
      case "date":
        items.sort((a, b) => {
          //We already know these are set, but the compiler can't tell.
          const aVal = a[field];
          const bVal = b[field];
          const aIsMoment = moment.isMoment(aVal);
          const bIsMoment = moment.isMoment(bVal);
          if (aIsMoment && bIsMoment) {
            return bVal.valueOf() - aVal.valueOf();
          } else if (aIsMoment) {
            return -1;
          } else if (bIsMoment) {
            return 1;
          }
          else {
            return 0;
          }
        });
        break;
      case "number":
        items.sort((a, b) => {
          //We already know these are set, but the compiler can't tell.
          const aVal = Number(a[field]);
          const bVal = Number(b[field]);
          return aVal - bVal;
        });
        break;
    }
  }

  private determineItemsToRender() {
    let items = this.props.filter ? this.state.items.filter(this.props.filter.passes) : this.state.items;
    //If sorting is enabled
    if (this.state.sortSetting) {
      let sortedCol = this.props.columns.find(col => col.colId === this.state.sortSetting!.colId);
      if (sortedCol && sortedCol.sortFunction) {
        if (typeof sortedCol.sortFunction === 'function') {
          items.sort(sortedCol.sortFunction);
        } else {
          if (sortedCol.field !== undefined) {
            this.processDefaultSort(items, sortedCol.sortFunction, sortedCol.field!);
          } else {
            Debug.throw(new DeveloperError("You can't use the built in sort options on a column that doesn't have field defined"));
          }
        }
        if (this.state.sortSetting.direction === 'desc') {
          items = items.reverse();
        }
      }
    } else if (this.props.defaultSort) {
      if (typeof this.props.defaultSort === 'function') {
        items.sort(this.props.defaultSort);
      } else {
        const [field, type] = this.props.defaultSort;
        this.processDefaultSort(items, type, field);
      }
    }
    //Handle pages
    if (this.usePagination) {
      let start = (this.state.currentPage - 1) * this.props.itemsPerPage!;
      let end = this.state.currentPage * this.props.itemsPerPage!;
      if (end > this.props.items.length) {
        end = this.props.items.length;
      }
      items = items.slice(start, end);
    }

    return items;
  }

  private renderRows() {
    let items = this.determineItemsToRender();
    return items.map(this.renderRow);
  }

  private preprocessColumns() {
    for (let col of this.props.columns) {
      if (!col.colId) {
        col.colId = col.field ?? DOMUtils.generateDOMUuid();
      }
    }
  }

  @autobind
  private onPageChanged(newPage: number, oldPage: number) {
    if (newPage > 0 && newPage < this.totalPages + 1) {
      this.setState({
        currentPage: newPage
      });
    } else {
      this.onPageChanged(1, oldPage);
    }
  }

  @autobind
  private renderRow(item: T, index: number): JSX.Element {
    return (
      <Table.Row
        key={`row_${index}`}
        item={item}
        columns={this.props.columns}
        index={index}
        renderCollapseContent={this.props.renderCollapseContent}
        shouldRenderCollapseContent={this.props.shouldRenderCollapseContent}
      />
    )
  }

  render() {
    let result: JSX.Element;
    if (this.props.responsive) {
      result = (
        <div className={TableClasses.Responsive} style={{ padding: '4px' }}>
          {this.renderTable()}
        </div>
      );
    } else {
      result = this.renderTable();
    }
    return result;
  }
}

export namespace Table {

  interface IRowProps<T> {
    columns: ITableColumn<T>[]
    item: T;
    index: number;
    renderCollapseContent?: (item: T, open:boolean) => JSX.Element | JSX.Element[]
    shouldRenderCollapseContent?: (item: T) => boolean;
  }

  interface IRowState {
    collapsed: boolean;
  }

  export class Row<T> extends React.Component<IRowProps<T>, IRowState> {

    constructor(props: IRowProps<T>) {
      super(props);
      this.state = {
        collapsed: true
      };
    }

    @autobind
    private handleRowCollapse() {
      this.setState({
        collapsed: !this.state.collapsed
      });
    }

    @autobind
    private renderColumn(item: T, rowIndex: number, col: ITableColumn<T>, colIndex: number) {
      let value;
      if (col.valueGetter) {
        value = col.valueGetter(item);
      } else if (col.field) {
        value = item[col.field]
      } else {
        throw new DeveloperError("field or value getter must be defined on a column");
      }

      if (col.valueFormatter) {
        value = col.valueFormatter({
          originalValue: value,
          data: item,
          column: col
        });
      }
      let cellComponent: React.ReactNode | undefined;
      if (col.cellRenderer) {
        cellComponent = col.cellRenderer({
          originalValue: value,
          stringValue: String(value),
          column: col,
          data: item
        });
      }

      //Add cell render pipeline here if necessary
      if (col.isRowHeader) {
        return <th key={`${col.colId}_${rowIndex}_${colIndex}`} scope={col.isRowHeader ? "row" : undefined}>{cellComponent ?? `${value}`}</th>
      } else {
        return <td key={`${col.colId}_${rowIndex}_${colIndex}`}>{cellComponent ?? `${value}`}</td>
      }
    }

    @autobind
    private handleKeyPress(event: React.KeyboardEvent<HTMLTableRowElement>) {
      if (event.code === "Enter" || event.code === "Space") {
        event.currentTarget.click();
      }
    }


    render() {
      let columns = this.props.columns.map((col, colIndex) => this.renderColumn(this.props.item, this.props.index, col, colIndex));
      if (this.props.renderCollapseContent) {
        columns.unshift(<td aria-hidden key="blank_slot" />);
      }
      const shouldRenderCollapse = this.props.shouldRenderCollapseContent ? this.props.shouldRenderCollapseContent(this.props.item) : true;
      if (this.props.renderCollapseContent && shouldRenderCollapse) {
        const mainRowClasses = [tableStyles["collapsible-row"], this.state.collapsed ? "" : tableStyles.open];
        const contentRowClasses = [tableStyles["content-row"], this.state.collapsed ? "" : tableStyles.open];
        const ariaContentId = `table_row_${uuid.v4()}`;
        return [
          <tr
            key="main_row"
            className={mainRowClasses.join(' ')}
            onClick={this.handleRowCollapse}
            aria-controls={ariaContentId}
            tabIndex={0}
            onKeyPress={this.handleKeyPress}
            aria-expanded={this.state.collapsed? "false" : "true" }
          >
            {columns}
          </tr>,
          <tr
            key="content_row"
            className={contentRowClasses.join(' ')}
            id={ariaContentId}
          >
            <td colSpan={columns.length}>
              <AnimatedHeightDiv open={!this.state.collapsed}>
                <div className={tableStyles.content}>
                  {this.props.renderCollapseContent(this.props.item,!this.state.collapsed)}
                </div>
              </AnimatedHeightDiv>
            </td>
          </tr>
        ]
      } else {
        return (
          <tr>
            {columns}
          </tr>
        )
      }

    }
  }
}