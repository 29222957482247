import React from "react";
import { AppContext } from "@utils/AppContext";
import { Product } from "@models/Product";
import { autobind } from "@utils/Decorators";
import { LoadState } from "@utils/Enums/LoadState";
import { GridLayout } from "@components/MWF/GridLayout";
import { PaginationControl } from "@components/MWF/Pagination";
import { Spinner } from "@components/MWF/Spinner";
import { History } from 'history';
import { Banner } from "@components/MWF/Banner";
import { numberOrDefault } from "@utils/TypeUtils";
import { CatalogAPI } from "@utils/APIs/CatalogAPI";
import catalogStyles from './Catalog.module.css'
import { ProductCard, ProductCardLayout } from "@components/MWF/ProductCard";
import { DOMUtils } from "@utils/DOMUtils";
import { AppRoute } from "@components/Routing/AppRoute";
import { CatalogFilterArea } from "../CatalogFilterArea";
import { ScreenSizeBreakpoint } from "@utils/MWF/ScreenSizeBreakpoints";
import { CommandBarButton, Dropdown, DropdownMenuItemType, Icon, IconButton, IDropdownOption, IDropdownProps, IStackTokens, Label, Stack } from "@fluentui/react";
import { ProductFilter, ProductFilterConst } from "@models/ProductFilter";

interface ICatalogProps {
  urlParams?: URLSearchParams
  page?: number;
  history: History;
}


interface ICatalogState {
  currentPage: number;
  totalPages: number;
  cardsPerPage: number;
  loadState: LoadState;
  products: Product[];
  categories: string[];
  productFilter: ProductFilter;
  clearFilterBtnVisible: boolean;
}

const SPINNER_ARIA_ID = DOMUtils.generateDOMUuid();
export const iconStyles = { marginRight: '8px' };
export const dropdownStyles = { dropdown: { width: 300 } };
const stackTokens: IStackTokens = { childrenGap: 20 };

export class Catalog extends React.Component<ICatalogProps, ICatalogState> {

  public static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;


  constructor(props: ICatalogProps) {
    super(props);
    this.setStateFromProps();
  }

  public setStateFromProps()
  {
    this.state = {
      currentPage: this.props.page ?? 1,
      totalPages: 1,
      cardsPerPage: 20,
      loadState: LoadState.Loading,
      products: [],
      categories: [],
      productFilter: new ProductFilter(this.props.urlParams),
      clearFilterBtnVisible: false,
    };
  }

  public async componentDidMount() {
    try {
      this.updateClearFilterBtnState();
      let products = await this.loadProducts(ProductFilter.toURIQuery(this.state.productFilter));
      let categories = await this.loadCategories();
      let totalPages = Math.ceil(products.length / this.state.cardsPerPage);
      totalPages = totalPages === 0 ? 1 : totalPages;
      this.setState({
        loadState: LoadState.Complete,
        products: products,
        categories:categories,
        totalPages: totalPages,
        currentPage: this.state.currentPage > totalPages ? 1 : this.state.currentPage
      });
    } catch (err) {
      this.setState({
        loadState: LoadState.Failed
      });
    }
  }

  private dateRangeFilterRefreshNeeded(oldState: ICatalogState): boolean
  {
    var refreshNeeded = false;

    var filterStateChanged = this.state.productFilter.dateRangeFilter !== oldState.productFilter.dateRangeFilter;
    var afterDateChanged = this.state.productFilter.afterDate !== oldState.productFilter.afterDate;
    var beforeDateChanged = this.state.productFilter.beforeDate !== oldState.productFilter.beforeDate;
    var beforeDateExists = (this.state.productFilter.beforeDate!==undefined);
    var afterDateExists = (this.state.productFilter.afterDate!==undefined);
    var dateRangeFilterValid = this.state.productFilter.dateRangeFilter && (beforeDateExists || afterDateExists);
    // If the current state changed and is false or true and we have a valid after & before date 
    if(filterStateChanged && (!this.state.productFilter.dateRangeFilter || dateRangeFilterValid))
    {
      refreshNeeded = true;
    }

    // if we have a daterange filter on and the before/after date changed then refresh
    if(this.state.productFilter.dateRangeFilter && (afterDateChanged || beforeDateChanged ))
    {
      refreshNeeded = true;
    }

    return refreshNeeded;
  }

  private sortFilterChanged(oldState: ICatalogState): boolean
  {
    var changed = false;
    // If we have enabled/disabled the alpha/date sort or the alpha/date sort type changed 
    // then we need to refresh
    if(this.state.productFilter.selectedSortKey !== oldState.productFilter.selectedSortKey)
    {
      changed = true;
    }
    return changed;
  }

  

  public async componentDidUpdate(oldProps: ICatalogProps, oldState: ICatalogState) {
    if (this.state.currentPage !== oldState.currentPage) {
      this.updateQueryStringWithPage(this.state.currentPage);
    }

    var searchQuery = this.props.urlParams?.get(this.context.languagePack.catalog_page.search_query_param) ?? undefined;

    //If we search, we should go back to page 1 unless part of the query string already contains a page number in it.
    if( (searchQuery !== oldState.productFilter.searchQuery) 
        || (this.state.productFilter.catFilters !== oldState.productFilter.catFilters)
        || this.dateRangeFilterRefreshNeeded(oldState) 
        || this.sortFilterChanged(oldState)
        || this.state.productFilter.searchType!==oldState.productFilter.searchType
        || this.state.productFilter.osFilter!==oldState.productFilter.osFilter
        || this.state.productFilter.archFilter!==oldState.productFilter.archFilter
        || this.state.productFilter.artifactFilter!==oldState.productFilter.artifactFilter) {
      let page = numberOrDefault(this.props.urlParams?.get(this.context.languagePack.catalog_page.page_query_param) ?? "", 1, true);
      //Reset to loading
      this.setState({
        loadState: LoadState.Loading,
        products: [],
        totalPages: 1,
        currentPage: 1
      });
      // If a search has begun, automatically switch to relevance
      if (!!searchQuery && !oldState.productFilter.searchQuery) {
        var pdctFlter = this.state.productFilter;
        pdctFlter.searchQuery = searchQuery;
        pdctFlter.selectedSortKey = ProductFilterConst.RelevanceSortKey;
        this.setProductFilter(pdctFlter);
        // If the search has cleared, switch to sorting a-z
      } else if (!searchQuery && this.state.productFilter.selectedSortKey == ProductFilterConst.RelevanceSortKey) {
        var pdctFlter = this.state.productFilter;
        pdctFlter.searchQuery = searchQuery;
        pdctFlter.selectedSortKey = ProductFilterConst.DefaultSortKey;
        this.setProductFilter(pdctFlter);
      }
      else if(searchQuery != oldState.productFilter.searchQuery)
      {
        this.state.productFilter.searchQuery = searchQuery;
      }
      // If we have a date range filter default to sorting by latest last published date
      if(this.state.productFilter.dateRangeFilter 
        && oldState.productFilter.selectedSortKey==this.state.productFilter.selectedSortKey
        && this.state.productFilter.selectedSortKey != ProductFilterConst.RelevanceSortKey
        && this.state.productFilter.selectedSortKey != ProductFilterConst.OldestDateSortKey )
      {
        var pdctFlter = this.state.productFilter;
        pdctFlter.selectedSortKey = ProductFilterConst.LatestDateSortKey;
        this.setProductFilter(pdctFlter);
      }
      try {
        this.updateClearFilterBtnState();
        let products = await this.loadProducts(ProductFilter.toURIQuery(this.state.productFilter));
        let categories = await this.loadCategories();
        let totalPages = Math.ceil(products.length / this.state.cardsPerPage);
        totalPages = totalPages === 0 ? 1 : totalPages;
        this.setState({
          loadState: LoadState.Complete,
          products: products,
          categories: categories,
          totalPages: totalPages,
          currentPage: page > totalPages ? 1 : page
        });
      } catch (err) {
        this.setState({
          loadState: LoadState.Failed
        });
      }
    }

  }

  @autobind
  private clearSearch() {
    let params = new URLSearchParams(this.props.history.location.search);
    params.delete(this.context.languagePack.catalog_page.search_query_param);
    params.delete(ProductFilterConst.SearchTypeParam);
    this.props.history.push({
      search: params.toString()
    });
  }

  private async loadProducts(queryStr: string): Promise<Product[]> {
    return await CatalogAPI.getProducts(queryStr);
  }

  private async loadCategories(): Promise<string[]> {
    return await CatalogAPI.getAllProductCategories();
  }

  private generateCards() {
    let currentPage = this.state.currentPage;
    let productsOnPage = this.state.products.slice((currentPage - 1) * this.state.cardsPerPage, currentPage * this.state.cardsPerPage);
    return productsOnPage.map((product, index) => (
      <ProductCard
        key={`product_card_${index}`}
        product={product}
      />
    ));
  }

  private renderCardArea() {
    let component: JSX.Element;
    switch (this.state.loadState) {
      case LoadState.Loading:
        component = (
          <div className={catalogStyles["spinner-wrapper"]}>
            <Spinner large ariaId={SPINNER_ARIA_ID} />
          </div>
        )
        break;
      case LoadState.Complete:
        if (this.state.products.length > 0) {
          component = (
            <>
              <ProductCardLayout className="mt-4">
                {this.generateCards()}
              </ProductCardLayout>
              <PaginationControl
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                onPageChanged={this.onPageChanged}
                ariaLabel={this.context.languagePack.catalog_page.pagination_control_aria_label}
                getPageUrl={this.getRouteWithPage}
              />
            </>
          )
        } else {
          component = (
            <div aria-live="polite" className={catalogStyles["no-content"]}>
              {
                this.state.productFilter.searchQuery ?
                  <h2>{this.context.languagePack.catalog_page.errors.no_results.replace("{0}", this.state.productFilter.searchQuery)}</h2> :
                  this.context.languagePack.catalog_page.errors.no_products
              }
            </div>
          );
        }
        break;
      case LoadState.Failed:
        component = (
          <div className={catalogStyles["no-content"]}>
            <h2>{this.context.languagePack.catalog_page.errors.load_failed}</h2>
          </div>
        )
        break;
    }
    return component;
  }

  @autobind
  private onPageChanged(newPage: number, oldPage: number) {
    this.setState({
      currentPage: newPage
    });
  }

  @autobind
  private getRouteWithPage(page: number) {
    const { page_query_param: pageQueryParam } = this.context.languagePack.catalog_page;
    let query = new URLSearchParams(this.props.history.location.search);
    if (query.has(pageQueryParam)) {
      query.set(pageQueryParam, `${page}`);
    } else {
      query.append(pageQueryParam, `${page}`);
    }
    return AppRoute.getRoute(AppRoute.Catalog, this.context.locale, `?${query.toString()}`);
  }

  private updateQueryStringWithPage(page: number) {
    const route = this.getRouteWithPage(page);
    this.props.history.push(route);
  }  

  private addOrRemoveFilter(filters: string[], filter: string)
  {
    let copy = [...filters];
    // If we have the filter then remove it, otherwise push it
    if(copy.includes(filter) )
    {
        copy.splice(copy.indexOf(filter), 1);
    }
    else
    {
      copy.push(filter);
    }
    return copy;
  }

  @autobind
  private setFilter(filterType:ProductFilterConst.FilterCollection, filter: string )
  {
    const {catFilters, osFilter, archFilter, artifactFilter} = this.state.productFilter;
    switch(filterType)
    {
      case(ProductFilterConst.FilterCollection.Category):
      {
        this.setProductFilter({...this.state.productFilter, catFilters: this.addOrRemoveFilter(catFilters, filter)});
        return;
      }
      case(ProductFilterConst.FilterCollection.OS):
      {
        this.setProductFilter({...this.state.productFilter, osFilter: this.addOrRemoveFilter(osFilter, filter)});
        return;
      }
      case(ProductFilterConst.FilterCollection.Architecture):
      {
        this.setProductFilter({...this.state.productFilter, archFilter: this.addOrRemoveFilter(archFilter, filter)});
        return;
      }
      case(ProductFilterConst.FilterCollection.Artifact):
      {
        this.setProductFilter({...this.state.productFilter, artifactFilter: this.addOrRemoveFilter(artifactFilter, filter)});
        return;
      }
      default:
      {
        return;
      }
    }
  }

  @autobind
  private setProductFilter(filter: ProductFilter)
  {
    this.setState({
      productFilter: filter
    });
    
    this.updateRouteWithProductFilter(filter);
  }

  private updateRouteWithProductFilter(filter: ProductFilter)
  {
    var url = ProductFilter.toURIQuery(filter);
    if(this.state.currentPage!=1 && this.state.totalPages>1 && url!="")
    {
      url+=`&${this.context.languagePack.catalog_page.page_query_param}=${this.state.currentPage}`;
    }
    const route = AppRoute.getRoute(AppRoute.Catalog, this.context.locale,url);
    this.props.history.push(route);
  }

  private updateClearFilterBtnState()
  {
    var enabled = false;
    if(this.state.productFilter.catFilters.length>0 || this.state.productFilter.artifactFilter.length>0
      || this.state.productFilter.archFilter.length>0 || this.state.productFilter.osFilter.length>0
      || this.state.productFilter.dateRangeFilter )
    {
      enabled = true;
    }
    
    this.setState({
      clearFilterBtnVisible: enabled
    });
  }

  private setSortFromDropDown(option: IDropdownOption | undefined)
  {
    var pdtFilterCopy = Object.assign([], this.state.productFilter);
    pdtFilterCopy.selectedSortKey = option?.key.toString() ?? "";

    this.setState({
      productFilter: pdtFilterCopy,
    });
    this.updateRouteWithProductFilter(pdtFilterCopy);
  }
  
  private onRenderLabel = (props?: IDropdownProps): JSX.Element => {
    return (
        <Label className="sortByLabel">{props?.label}</Label>
    );
  };
  
  private onRenderOption = (option?: IDropdownOption): JSX.Element => {
    return (
      <div>
        {option?.data && option.data.icon && (
          <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
        )}
        <span>{option?.text}</span>
      </div>
    );
  };

  private renderCancelFilterButtons() {
    var filters: JSX.Element[] = []; 
    const {searchQuery,dateRangeFilter,afterDate, beforeDate, catFilters, osFilter, archFilter, artifactFilter} = this.state.productFilter;

    // Search filter button
    if(searchQuery)
    {
      filters.push(<div className={catalogStyles["remove-filter-button"]} key={ProductFilterConst.SearchParam}><CommandBarButton className="clearFiltersButton sortButton" iconProps={{ iconName: 'Cancel' }} title={"Remove Filter"} text={"\""+searchQuery+"\""} onClick={() => this.setProductFilter({...this.state.productFilter, searchQuery: undefined})} /></div>);

    }
    // Add date filter buttons
    if(dateRangeFilter)
    {
      if (afterDate ) {
        filters.push(<div className={catalogStyles["remove-filter-button"]} key={afterDate.toDateString()}><CommandBarButton className="clearFiltersButton sortButton" iconProps={{ iconName: 'Cancel' }} title={"Remove Filter"} text={"After " + afterDate.toDateString()} onClick={() => this.setProductFilter({...this.state.productFilter, afterDate: undefined})} /></div>);
      }
      if (beforeDate) {
        filters.push(<div className={catalogStyles["remove-filter-button"]} key={beforeDate.toDateString()}><CommandBarButton className="clearFiltersButton sortButton" iconProps={{ iconName: 'Cancel' }} title={"Remove Filter"} text={"Before " + beforeDate.toDateString()} onClick={() => this.setProductFilter({...this.state.productFilter, beforeDate: undefined})} /></div>);
      }
    }

    // Add artifact, category, os, arch buttons
    artifactFilter.forEach((filter: string) =>
    {
      filters.push(<div className={catalogStyles["remove-filter-button"]} key={filter}><CommandBarButton className="clearFiltersButton sortButton" iconProps={{ iconName: 'Cancel' }} title={"Remove Filter"} text={filter} onClick={() => this.setFilter(ProductFilterConst.FilterCollection.Artifact, filter)} /></div>);
    })
    catFilters.forEach((filter: string) =>
    {
      filters.push(<div className={catalogStyles["remove-filter-button"]} key={filter}><CommandBarButton className="clearFiltersButton sortButton" iconProps={{ iconName: 'Cancel' }} title={"Remove Filter"} text={filter} onClick={() => this.setFilter(ProductFilterConst.FilterCollection.Category, filter)} /></div>);
    })
    osFilter.forEach((filter: string) =>
    {
      filters.push(<div className={catalogStyles["remove-filter-button"]} key={filter}><CommandBarButton className="clearFiltersButton sortButton" iconProps={{ iconName: 'Cancel' }} title={"Remove Filter"} text={filter} onClick={() => this.setFilter(ProductFilterConst.FilterCollection.OS, filter)} /></div>);
    })
    archFilter.forEach((filter: string) =>
    {
      filters.push(<div className={catalogStyles["remove-filter-button"]} key={filter}><CommandBarButton className="clearFiltersButton sortButton" iconProps={{ iconName: 'Cancel' }} title={"Remove Filter"} text={filter} onClick={() => this.setFilter(ProductFilterConst.FilterCollection.Architecture, filter)} /></div>);
    })
    return <div className={catalogStyles["remove-filter-container"]}>{filters}</div>;
  }

  @autobind
  private clearFilters()
  {
    var pdtFilterCopy = Object.assign([], this.state.productFilter);

    pdtFilterCopy.catFilters = [];
    pdtFilterCopy.osFilter = [];
    pdtFilterCopy.archFilter = [];
    pdtFilterCopy.artifactFilter = [];
    pdtFilterCopy.dateRangeFilter =false;
    pdtFilterCopy.afterDate = undefined;
    pdtFilterCopy.beforeDate = undefined;
    
    this.setProductFilter(pdtFilterCopy);

  }
  
  private onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {

    if(options)
    {
      const option = options[0];
      return (
        <div>
        {option.data && option.data.icon && (
          <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
          )}
        <span>{option.text}</span>
      </div>
      );
    }
    else
    {
      return <div></div>;
    }
  };

  private getSearchResultsLabel(): string
  {
    if(this.state.loadState == LoadState.Loading)
    {
      return "Loading results...";
    }
    else 
    {
      return "Showing " + this.state.products.length + " results";
    }
  }
  

  render() {

     const sortOptions: IDropdownOption[] = [
      { key: 'HeaderScoring', text: 'Scoring', itemType: this.state.productFilter.searchQuery ? DropdownMenuItemType.Header : DropdownMenuItemType.Normal, hidden: !this.state.productFilter.searchQuery },
      { key: ProductFilterConst.RelevanceSortKey, text: 'Relevance', data: { icon: 'SortLines' }, hidden: !this.state.productFilter.searchQuery},
      { key: 'dividerScoring', text: '-', itemType: DropdownMenuItemType.Divider, hidden: !this.state.productFilter.searchQuery },
      { key: 'Header', text: 'Product Name', itemType: DropdownMenuItemType.Header },
      { key: ProductFilterConst.DefaultSortKey, text: 'A-Z', data: { icon: 'Ascending' } },
      { key: 'z-a,Name', text: 'Z-A', data: { icon: 'Descending' } },
      { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
      { key: 'Header2', text: 'Last Published Date', itemType: DropdownMenuItemType.Header },
      { key: 'date_desc', text: 'Latest', data: { icon: 'GroupedDescending' } },
      { key: 'date_asc', text: 'Oldest', data: { icon: 'GroupedAscending' } }
    ];

    const hasSearched = !!this.state.productFilter.searchQuery;
    //initializeIcons();
    return (
      <>
        <Banner
          content={{
            paragraph: {
              title: this.context.languagePack.app_title,
              text: this.context.languagePack.catalog_page.banner_message.default
                
            }
          }}
        />
        
        <GridLayout>
          <GridLayout.Row noGutters>
             <GridLayout.Column
              widthSettings={[
                ['default', 2],
                [ScreenSizeBreakpoint.lg, 2],
                [ScreenSizeBreakpoint.xl, 2]
              ]}
              desktopOnly
              classes={["filterCol"]}
            >
              <CatalogFilterArea 
                uiFilterCategories={this.state.categories.sort()}
                productFilter={this.state.productFilter}
                clearFilterBtnVisible={this.state.clearFilterBtnVisible}
                clearFilters={this.clearFilters}
                setProductFilter={filter => this.setProductFilter(filter)}
                setFilter={this.setFilter}
              /> 
            </GridLayout.Column> 
            <div className="productArea">
              <GridLayout.Row noGutters >
                <div className={catalogStyles["sort-wrapper"]}>
                  <Stack horizontal verticalAlign="center">
                    <Label className="searchTotal">{this.getSearchResultsLabel()}</Label>
                    {this.renderCancelFilterButtons()}
                  </Stack>
                  <Dropdown 
                    placeholder="Select a sort option"
                    label="Sort by"
                    ariaLabel="Sort by "
                    selectedKey={this.state.productFilter.selectedSortKey}
                    onRenderTitle={this.onRenderTitle}
                    onRenderOption={this.onRenderOption}
                    onRenderLabel={this.onRenderLabel}
                    styles={dropdownStyles}
                    options={sortOptions}
                    onChange={(evt,option) => this.setSortFromDropDown(option)}
                  />
                </div>
              </GridLayout.Row>
              <GridLayout.Column classes={[/*"pl-md-4"*/]}>
                {this.renderCardArea()}
              </GridLayout.Column>
            </div>
          </GridLayout.Row>
        </GridLayout>
      </>
    )
  }
}

export { ProductFilter };
